<template>
  <section class="text-gray-600 body-font">
    <div class="container px-2 py-5 mx-auto">
      <!-- Language Buttons -->
      <div class="flex justify-center mb-4 gap-4">
        <button
            v-for="lang in languages"
            :key="lang.code"
            @click="changeLanguage(lang.code)"
            class="px-4 py-2 bg-yellow-300 text-white rounded hover:bg-yellow-400"
        >
          {{ lang.name }}
        </button>
      </div>

      <div class="text-center mb-2 text-yellow-300">
        <h1 class="text-2xl font-bold">{{ $t('constitution') }}</h1>
      </div>

      <!-- Constitution List -->
      <div class="grid grid-cols-1 gap-4">
        <div
            v-for="constitution in processedConstitutions"
            :key="constitution.id"
            class="relative border border-gray-200 p-4 rounded-md bg-white shadow-md"
        >
          <!-- Dark Green Line on the Left -->
          <div class="absolute left-0 top-0 h-full w-1 bg-yellow-300 rounded-l-md"></div>

          <!-- Name with Dark Green Underline -->
          <h2 class="text-xl font-bold mb-2 underline decoration-yellow-300 text-yellow-300 decoration-4">
            {{ constitution.translates[currentLanguage].name }}
          </h2>

          <!-- Content -->
          <div
              class="text-gray-600"
              v-html="constitution.translates[currentLanguage].content"
          ></div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: 'az',
      languages: [
        { code: 'az', name: 'Azərbaycan' },
        { code: 'en', name: 'English' },
        { code: 'ru', name: 'Русский' }
      ]
    }
  },
  computed: {
    constitutions() {
      return this.$store.state.ConstitutionStore?.constitutions || [];
    },
    processedConstitutions() {
      return this.constitutions.map(constitution =>
          constitution.form || constitution
      );
    }
  },
  methods: {
    changeLanguage(languageCode) {
      this.currentLanguage = languageCode;
      this.$i18n.locale = languageCode;
    }
  },
  created() {
    if (this.constitutions.length === 0) {
      this.$store.dispatch('ConstitutionStore/getConstitutions');
    }
  }
}
</script>